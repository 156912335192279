import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import Select from "react-select";
import ReactToPrint from "react-to-print";
import axios from 'axios';
import Image from '../../assets/dist/img/avatar_2.png';
import { UserContext } from '../../context/UserContext';
function mystudentResult(props) {
    const id = props.match.params.id;
    console.log(props.match.params.id);

    return (
        <>
        <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h4 className="m-0">View Student Result:</h4>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className='mr-3'>
                                </li>
                                <li className='mr-3'><Link to='/staff/index'><button type="button" className="btn btn-block btn-dark btn-sm" data-tip="Dashboard" data-place="bottom"><i className='fa fa-home'></i> </button></Link></li>
                            </ol>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className='col-12'>
                            <div className="alert alert-danger alert-dismissible">
                                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                                <h5><i className="icon fas fa-info" /> Info!</h5>
                                We keep in mind user friendly system for every users. You can now easily check your result on the go.
                            </div>

                        </div>
                    </div>
                    <div className="overlay-wrapper">

                       
                    </div>
                </div>
            </div>
    </>
    )
}

export default mystudentResult;